<template>
  <div class="app-container">
    <avue-crud
      ref="crud"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #menu="{ row }">
        <changeStateBtn class="menu-btn-item" :data="row" :sup_this="sup_this"></changeStateBtn>
        <el-button class="menu-btn-item" type="text" size="small" @click="viewDetails(row)">查看详情</el-button>
      </template>

      <template #orderType="{ row }">
        {{ row.orderType === 2 ? 'FBA订单' : '普通订单' }}
      </template>

      <!-- 物流 -->
      <template #expressCompanyId="{ row }">
        {{ expressName(row) }}
      </template>
       <template #userName="{ row }">
        {{ row.userName }}<span v-if="row.userStatus === 2" style="color:red">(已注销)</span>
      </template>
    </avue-crud>

    <order-detail :visible.sync="orderDetailDialogVisible" :data="orderDetailData"></order-detail>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import { orderList as getList } from '@/api/order'
import changeStateBtn from '@/views/order/module/orderListModule/changeStateBtn'
import orderDetail from '@/views/order/orderDetail'

export default {
  components: {
    changeStateBtn,
    orderDetail
  },
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  data() {
    return {
      sup_this: this,
      resetMergeData: {
        sendBatchId: this.$route.query.sendBatchId,
        isReturnRelatedData: 1 // 是否返回订单orderItemDTOList：0不返回，1返回
      },
      orderDetailDialogVisible: false,
      orderDetailData: {}
    }
  },
  created() {},
  methods: {
    viewDetails(row) {
      this.orderDetailData = row
      this.orderDetailDialogVisible = true
    },
    expressName(scoped) {
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.expressCompanyName
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

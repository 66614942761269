var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('changeStateBtn',{staticClass:"menu-btn-item",attrs:{"data":row,"sup_this":_vm.sup_this}}),_c('el-button',{staticClass:"menu-btn-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.viewDetails(row)}}},[_vm._v("查看详情")])]}},{key:"orderType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderType === 2 ? 'FBA订单' : '普通订单')+" ")]}},{key:"expressCompanyId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.expressName(row))+" ")]}},{key:"userName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userName)),(row.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}}])}),_c('order-detail',{attrs:{"visible":_vm.orderDetailDialogVisible,"data":_vm.orderDetailData},on:{"update:visible":function($event){_vm.orderDetailDialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }